<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        src="@/assets/images/celcomLogo/logo.png"
        alt="Logo Celcom"
        style="height: 30px"
      />
    </b-link>

    <div
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Pago exitoso!!!
        </h2>
        <p class="mb-2">
          Su cobro se ha realizado con exito!.
        </p>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  name: 'payment',
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    };
  },
  
  mounted() {
  
  },
  updated(){
    this.$store.commit('appConfig/savePayment', false);
    
  },
  // if(window.location.reload)
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
